import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "./SideBar";
import emptyState from "../Images/emptyState.svg";
import cancel from "../Images/Cancel.svg";
import register from "../Images/completeReg.png";
import { useAppSelector } from "../../utils/redux/store";
import CampaignRequest from "../../utils/services/campaignRequests";
import { CurrencyFormatter, formatDate } from "../../utils/helpers/helper";
import WalletRequest from "../../utils/services/walletRequests";

function Dashboard() {
  const [empty, setEmpty] = useState(true);
  const [modal, setModal] = useState(false);
  const [isData, setData] = useState({});
  const [isContributor, setContributor] = useState([]);
  // const [walletDetials, setWalletDetails] = useState({});
  const [walletDetails, setWalletDetails] = useState(null);

  const { firstName, acceptanceStatus, educationInformation } = useAppSelector((state) => state.auth);
  const getCampaignsContributors = async () => {
    await CampaignRequest.getCampaignContributors().then((res) => {
      // console.log("campaign", res.data.result);

      setContributor(res.data.result);
    });
  };
  const getWalletDetials = async () => {
    try {
      const res = await WalletRequest.getWalletDetails();
      // console.log(res.data.result); // Check the result in the console
      setWalletDetails(res.data.result.userWalletDetails); // Set wallet details in state
    } catch (error) {
      console.error("Error fetching wallet details:", error);
    }
  };
  const getCampaign = async () => {
    await CampaignRequest.getCampaigns().then((res) => {
      // console.log("campaign", res.data.result);
      setData(res.data.result ? res.data.result : null);
    });
  };
  // const progressBar = "0%";
  const icon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4626 3.99352C16.7809 2.3486 14.4404 3.01148 13.0344 4.06738C12.4578 4.50033 12.1696 4.7168 12 4.7168C11.8304 4.7168 11.5422 4.50033 10.9656 4.06738C9.55962 3.01148 7.21909 2.3486 4.53744 3.99352C1.01807 6.1523 0.221719 13.2742 8.33953 19.2827C9.88572 20.4272 10.6588 20.9994 12 20.9994C13.3412 20.9994 14.1143 20.4272 15.6605 19.2827C23.7783 13.2742 22.9819 6.1523 19.4626 3.99352Z"
        stroke="#0C4AFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );

  useEffect(() => {
    getCampaignsContributors();
    getCampaign();
    getWalletDetials();
  }, []);
  useEffect(() => {
    if (educationInformation?.isCompleted !== "COMPLETED") {
      setModal(true);
    }
  }, []);
  // console.log("isdata", isData);
  console.log(educationInformation.totalFundingRequirement);

  const totalFundsRaised = walletDetails ? CurrencyFormatter(walletDetails.dollarAmount || 0, "$") : "Loading...";
  // const remainingTuitionBalance = walletDetails ? CurrencyFormatter(parseFloat(educationInformation?.totalFundingRequirement || 0) - (isContributor?.reduce((acc, item) => acc + item.amount, 0) || 0), "$") : "Loading...";
  const remainingTuitionBalance = walletDetails ? CurrencyFormatter(parseFloat(educationInformation?.totalFundingRequirement || 0) - (walletDetails.dollarAmount || 0), "$") : "Loading...";

  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] pb-[50px] lg:py-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className=" w-full flex-col justify-start items-start gap-[15px] inline-flex">
            <h3 className="self-stretch text-[#0c4aff] text-2xl font-medium  leading-[38px]">
              Welcome
              <span className=" font-bold capitalize ">, {firstName}!</span>
            </h3>
            <h3 className="self-stretch  text-[#6c757d] text-base font-normal  leading-normal">
              Total Funds Raised: <span className="text-[#3a3c3f]  font-semibold ">{totalFundsRaised}</span> | Remaining Tuition Balance: <span className="text-[#3a3c3f]  font-semibold ">{remainingTuitionBalance}</span>
            </h3>
          </div>
          {acceptanceStatus !== "ACCEPT" ? (
            <>
              <div className="h-fit py-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
                <img className=" 100:w-[100px] md:w-[150px]" src={emptyState} alt="" />
                <h4 className="text-center text-[#aab0b6] text-xs font-normal leading-normal">Nothing to show here</h4>
              </div>
            </>
          ) : (
            <>
              {/* PROGRESS BAR */}
              <div className="md:h-[148px] 100:h-fit w-full 100:p-6 md:p-8 bg-white rounded-xl shadow flex-col justify-center items-start gap-2.5 inline-flex">
                <h3 className="text-[#4a4f54] text-base font-medium  leading-[38px]">Funding Progress</h3>
                <div className="h-9 flex-col w-full justify-start items-end gap-2 inline-flex">
                  <div className=" h-[8px] items-start flex justify-start bg-[#E8F7FF] rounded-[8px] w-full">
                    <div style={{ width: `${isData?.progress || 0}%` }} className={`h-full  bg-[#0C4CFF] rounded-[8px] `}></div>
                  </div>
                  <div className=" w-full flex justify-end items-center">
                    <h5 className="text-[#4a4f54] text-xs font-medium  leading-tight">{isData?.progress || 0}% funded</h5>
                  </div>
                </div>
              </div>
              {/* CONTRIBUTION CARDS */}
              <div className=" w-full flex-col justify-start items-center 100:gap-[16px] md:gap-6 inline-flex">
                <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">Recent Contributions</h5>
                <div className=" w-full flex-1 flex 100:flex-wrap gap-[16px] lg:flex-row justify-start items-start">
                  <>
                    {isContributor?.length === 0 && (
                      <>
                        <div className="h-fit w-full py-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
                          <img className=" 100:w-[100px] md:w-[150px]" src={emptyState} alt="" />
                          <h4 className="text-center text-[#aab0b6] text-xs font-normal leading-normal">No recent contribution</h4>
                        </div>
                      </>
                    )}
                  </>
                  {Array.isArray(isContributor) &&
                    isContributor?.slice(0, 3)?.map((item) => (
                      <div key={item.id} className="h-fit flex-grow lg:w-[348px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start inline-flex">
                        <div className="w-fit flex-col justify-start items-start gap-2.5 inline-flex">
                          <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">{item?.contributor === "null" ? "Anonymous" : item?.contributor}</h4>
                          <h3 className="text-[#3a3c3f] text-2xl font-semibold">{CurrencyFormatter(item?.amount, "$")}</h3>
                          <h5 className="text-[#808990] text-xs font-normal">{formatDate(new Date(item?.date))}</h5>
                        </div>
                        <div className="w-12 h-12 bg-[#d5efff] flex justify-center items-center rounded-[28px] border-8 border-[#e8f7ff]">{icon}</div>
                      </div>
                    ))}
                </div>
              </div>
              {/* BUTTONS */}
              <div className="h-10 w-full 100:flex-col 100:justify-start 100:items-center flex-1 phone:flex-row phone:justify-end phone:items-center gap-3 inline-flex">
                <Link className={` ${isData === null ? "" : " cursor-not-allowed"} 100:w-full phone:w-fit`} to={isData === null ? "/user/campaign-form" : ""}>
                  <div
                    className={`h-10  text-sm flex-grow font-medium leading-tight px-4 py-2.5 ${
                      isData === null ? "bg-white text-[#4a4f54] border-[#aab0b6]" : "text-[#dce0e5] border-[#fff]"
                    } rounded-lg shadow border  justify-center items-center gap-2 flex`}
                  >
                    Create campaign
                  </div>
                </Link>
                <Link className=" 100:w-full phone:w-fit" to="/user/loan">
                  <div className="h-10 text-white text-sm font-medium flex-grow leading-tight px-4 py-2.5 bg-[#0c4cff] rounded-lg shadow border border-[#0c4cff] justify-center items-center gap-2 flex">Apply for loan</div>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setModal(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full phone:w-[400px] 100:h-[450px] md:h-fit overflow-y-scroll  p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full h-fit flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <img className=" " src={register} alt="Confirm" />

                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Complete your registration</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">Welcome to Ije! Fill in the remaining information to create a campaign and request for loans.</p>
                    </div>
                    <div className="self-stretch p-4 bg-gray-100 rounded-lg shadow-sm">
                      <h4 className="text-gray-700 text-[16px] font-semibold mb-2">Documents Required:</h4>
                      <ul className="list-disc gap-1 flex flex-col justify-start list-inside text-gray-500 text-sm">
                        <li>Proof of Identity (e.g., Passport, National ID)</li>
                        <li>Proof of Address (e.g., Bank Statement)</li>
                        <li>Academic Certificates</li>
                        <li>Bank Statements (Last 3 months)</li>
                      </ul>
                    </div>
                  </div>
                  <Link onClick={() => window.scrollTo(0, 0)} className=" w-full" to="/auth/signup">
                    <div className="leading-[20px] cursor-pointer text-center border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">
                      Complete your registration
                    </div>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard;
