import SideBar from "./SideBar";
import Select from "react-select";
import usa from "../Images/United States of America (US).png";
import ngn from "../Images/Nigeria (NG).png";
import { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../Images/arrow-down-01.svg";
import { ReactComponent as ArrowUp } from "../Images/arrow-up-01.svg";
import { ReactComponent as Copy } from "../Images/copy.svg";
import WalletRequest from "../../utils/services/walletRequests";
import TransHistory from "./components/transHistory";
import { CurrencyFormatter, formatDate } from "../../utils/helpers/helper";
import emptyState from "../Images/emptyState.svg";
// import MiscRequest from "../../utils/services/miscRequests";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const currency = [
  {
    id: 2,
    icon: ngn,
    title: "NGN Wallet",
  },
  {
    id: 1,
    icon: usa,
    title: "USD Wallet",
  },
];
const currency2 = [
  {
    id: 2,
    title: "NGN History",
  },
  {
    id: 1,
    title: "USD History",
  },
];
function Wallet() {
  const [selectedCurrency, setSelectedCurrency] = useState(currency[0]);
  const [selectedCurrency2, setSelectedCurrency2] = useState(currency2[0]);
  // const [isData, setData] = useState({});
  const [info, setInfo] = useState(false);
  const [walletDetials, setWalletDetails] = useState({});
  const { id } = useParams();
  const [dollarRate, setDollarRate] = useState([]);
  const [transDetails, setTransDetials] = useState([]);
  const [todayRate, setTodayRate] = useState("");

  const getCampaignwallet = async () => {
    const sendId = parseFloat(id);
    // await MiscRequest.getCampaignWallet(sendId).then((res) => {
    //   // console.log("isdate", res);
    //   // setWalletDetails(res?.data?.result);
    // });
  };

  // transaction details
  const getNairaWalletTrans = async () => {
    await WalletRequest.getNairaTransactions().then((res) => {
      setTransDetials(res.data.result);
    });
  };

  const getWalletDetials = async () => {
    await WalletRequest.getWalletDetails().then((res) => {
      setWalletDetails(res.data.result.userWalletDetails);
      setTransDetials(res.data.result.userDollarTransactions || []);
    });
  };

  const getDollarLogs = async () => {
    await WalletRequest.getCurrentDollarRate().then((res) => {
      // console.log("user", res.data.result)
      setTodayRate(res?.data?.result.dollarRate || 0);
      setDollarRate(res.data.result.dollarDailyRateResponses || []);
    });
  };
  useEffect(() => {
    getWalletDetials();
    getDollarLogs();
    getCampaignwallet();
  }, []);
  const handleSelect = (selectedOption) => {
    setSelectedCurrency(selectedOption);
  };
  const handleSelect2 = (selectedOption2) => {
    // console.log(selectedOption2.id);
    if (selectedOption2.id === 1) {
      // getdollarWalletTrans();
    } else {
      getNairaWalletTrans();
    }
    setSelectedCurrency2(selectedOption2);
  };

  const formatOptionLabel = ({ icon, title }) => (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <img src={icon} alt={title} style={{ width: "20px", height: "20px" }} />
      <span className="placeholder:text-[#4a4f54] text-[#4a4f54] text-xs font-medium">{title}</span>
    </div>
  );
  const formatOptionLabel2 = ({ title }) => (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <span className="placeholder:text-[#4a4f54] 100:truncate text-[#4a4f54] text-xs font-medium">{title}</span>
    </div>
  );
  const copyLink = () => {
    const shareUrl = walletDetials?.virtualAccountNumber;

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success("Copied", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <>
      <SideBar />
      <div className=" flex h-fit 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className=" w-full justify-between flex items-center">
            <h2 className="self-stretch text-[#3a3c3f] text-2xl font-medium  leading-[38px]">Wallet</h2>
            <div onClick={() => setInfo(!info)} className="text-[#6c757d] cursor-pointer text-sm font-medium h-[29px] px-4 py-1.5 bg-[#effefa] rounded-[999px] border border-[#cafdf3] justify-center items-center gap-2.5 inline-flex">
              {walletDetials?.nairaWalletNumber} {info ? <ArrowUp /> : <Arrow />}
            </div>
            {info && (
              <>
                <div className="h-fit absolute 100:top-[160px] md:top-[22vh] lg:top-[80px] z-50 right-[30px] 100:w-[85%] iphone:w-[293px] p-2.5 bg-white rounded-xl shadow flex-col justify-start items-center gap-2.5 inline-flex">
                  <div className=" w-full p-2.5 flex-col justify-start items-start gap-1.5 inline-flex">
                    <div className=" w-fit px-1 py-2.5 justify-start items-start gap-1 inline-flex">
                      <h4 className="text-[#4a4f54] text-sm font-medium ">Bank:</h4>
                      <h5 className="text-[#4a4f54] text-sm font-normal ">{walletDetials?.bank}</h5>
                    </div>
                    <div className=" w-full bg-[#E5E7E8] h-[1px]"></div>
                    <div className=" w-full  px-1 py-2.5 justify-between items-start inline-flex">
                      <div className=" w-full flex justify-start items-center gap-[4px] ">
                        <h4 className="text-[#4a4f54] text-sm font-medium ">Account Number:</h4>
                        <h5 className="text-[#4a4f54] text-sm font-normal ">{walletDetials?.virtualAccountNumber}</h5>
                      </div>
                      <Copy onClick={copyLink} className=" cursor-pointer" />
                    </div>
                    <div className=" w-full bg-[#E5E7E8] h-[1px]"></div>
                    <div className=" w-fit px-1 py-2.5 justify-start items-start gap-1 inline-flex">
                      <h4 className="text-[#4a4f54] text-sm font-medium ">Account Name:</h4>
                      <h5 className="text-[#4a4f54] text-sm font-normal ">{walletDetials?.customerName}</h5>
                    </div>
                    <div className=" w-full bg-[#E5E7E8] h-[1px]"></div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className=" w-full   h-fit flex justify-start items-start p-6 bg-white rounded-2xl gap-10 ">
            <div className=" w-full  flex-col justify-start items-start gap-8 inline-flex">
              <div className=" w-full px-16 py-8 bg-[#f5f6f6] rounded-3xl justify-center items-center inline-flex">
                <div className=" flex-col justify-start items-center gap-6 inline-flex">
                  {/* <Select
                    className="text-[#4a4f54]   placeholder:text-[#4a4f54] text-xs font-medium  "
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#0C4CFF",

                        placeholder: "#4a4f54",
                        primary: "#D5EFFF",
                      },
                    })}
                    styles={customStyles}
                    value={selectedCurrency}
                    onChange={handleSelect}
                    options={currency}
                    formatOptionLabel={formatOptionLabel}
                    placeholder="Select currency"
                  /> */}

                  <div className="self-stretch h-[66px] flex-col justify-start items-center gap-2 flex">
                    <h4 className="opacity-60 text-center text-[#4a4f54] text-[16px] font-medium ">Total balance</h4>
                    <h2 className="text-center text-[#3a3c3f] text-[32px] font-bold ">{CurrencyFormatter(walletDetials?.dollarAmount || 0, "$" || 0)}</h2>
                  </div>
                </div>
              </div>
              {/* TRANSACTION TABLE */}
              <div className="w-full p-2.5 flex-col justify-start items-start gap-5 inline-flex">
                <div className="w-full 100:flex-col 100:gap-[16px] phone:flex-row 100:justify-start 100:items-start  phone:justify-between phone:items-center flex">
                  <h4 className="text-[#3a3c3f] text-base font-medium 100:truncate ">Transaction History</h4>
                  {/* <Select
                    className="  justify-start items-center gap-2 inline-flex text-[#4a4f54]  placeholder:text-[#4a4f54] text-xs font-medium  "
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#4a4f54",
                        borderColor: "#d5efff",
                        // color: "#4a4f54",
                        placeholder: "#4a4f54",
                        primary: "#D5EFFF",
                      },
                    })}
                    styles={{
                      control: (base) => ({
                        ...base,

                        width: "fit",
                        height: "27px",
                        outline: "none",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        color: "#4a4f54",
                        // border: "#4a4f54",
                        zIndex: "20",
                        borderColor: "#d5efff",
                        borderWidth: "1px",
                        borderRadius: "999px",
                      }),
                    }}
                    value={selectedCurrency2}
                    onChange={handleSelect2}
                    options={currency2}
                    formatOptionLabel={formatOptionLabel2}
                    // placeholder="Select currency"
                  /> */}
                </div>
                {/* TRANSACTION HISTORY */}
                {/* {transDetails === null ? (
                  <>
                    <div className="h-fit py-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
                      <img className=" 100:w-[100px] md:w-[150px]" src={emptyState} alt="" />
                      <h4 className="text-center text-[#aab0b6] text-xs font-normal leading-normal">Nothing to show here</h4>
                    </div>
                  </>
                ) : ( */}
                <TransHistory data={transDetails} dollar={true} />
                {/* )} */}
              </div>
            </div>
            <div className="w-[450px] 100:hidden md:flex   px-4 pb-6 border-l border-[#e5e7e8] flex-col justify-start items-center gap-8 inline-flex">
              <div className="h-28 w-full px-16 py-8 bg-[#e8f7ff] rounded-3xl justify-center items-center inline-flex">
                <div className="self-stretch h-12 flex-col justify-start items-center gap-1 flex">
                  <h5 className="opacity-60 text-center text-[#4a4f54] text-xs font-medium ">Todays USD Rate</h5>
                  <h3 className="text-center text-[#4a4f54] text-2xl font-semibold ">{<>{CurrencyFormatter(todayRate || 0, "$")}</>}</h3>
                </div>
              </div>
              <div className=" w-full p-2.5 flex-col justify-start items-start gap-6 inline-flex">
                <h3 className="text-[#3a3c3f] text-base font-medium ">Past Rate</h3>
                <div className=" w-full py-2.5 flex-col justify-start items-start gap-4 inline-flex">
                  {Array.isArray(dollarRate) &&
                    dollarRate?.map((item) => (
                      <div className=" w-full border-b py-[8px] px-[4px] border-b-[#E5E7E8] flex-col justify-start items-start gap-1.5 flex">
                        <div className=" w-full justify-start items-center gap-[6px] inline-flex">
                          <img className=" w-[30px] h-[30px]" src={usa} alt="usa" />

                          <div className=" w-full flex-col justify-start items-start gap-1 inline-flex">
                            <h4 className="text-[#4a4f54] text-[10px] font-semibold  leading-[14px]">USD</h4>
                            <h5 className="text-[#6c757d] text-[10px] font-medium ">{formatDate(new Date(item?.date))}</h5>
                          </div>
                          <div className="text-center text-[#4a4f54] text-xs font-medium ">{CurrencyFormatter(parseFloat(item.amount || 0), "$")}</div>
                        </div>
                      </div>
                    ))}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Wallet;
