import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../Images/back.svg";
import logo from "../Images/Logo.svg";
import { useEffect, useState } from "react";
import TextInput from "../shared/input/TextInput";
import { useFormik } from "formik";
import AuthRequest from "../../utils/services/authRequests";
import { toast } from "react-toastify";

function ForgotPassword() {
  const [navBar, setNavBar] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      await AuthRequest.forgotPassword(values.email).then((res) => {
        setLoading(false);

        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          localStorage.setItem("emailData", values.email);
          navigate("/auth/otpforgotpassword");
        } else {
          // console.log("error");

          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      });
    },
  });
  useEffect(() => {
    function handleScrollBackground() {
      if (window.scrollY >= 20) {
        setNavBar(true);
      } else {
        setNavBar(false);
      }
    }
    window.addEventListener("scroll", handleScrollBackground);

    return () => {
      window.removeEventListener("scroll", handleScrollBackground);
    };
  }, []);
  return (
    <>
      <div className=" bg-white flex-col h-screen w-full gap-[100px]  items-center flex justify-start  ">
        <div className="fixed  top-0 z-20 w-full flex items-center p-[10px] justify-center">
          <div className={` rounded-[50px]   px-[30px] my-[10px] ${navBar ? "bg-[#D5EFFF] " : ""} h-[75px] flex justify-between items-center w-[90%] `}>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={"/auth/login"}
            >
              <div className="text-[#0C4AFF] gap-[8px] flex justify-start items-center ">
                <Back />
                <h4 className="  text-[16px] font-medium leading-[24px] ">Back</h4>
              </div>
            </Link>
            <div>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/"
              >
                <img className="" src={logo} alt="Ije" />
              </Link>
            </div>
            <div className="w-[66px] 100:hidden phone:block"></div>
          </div>
        </div>
        <div className=" 100:w-[85%] pt-[180px] md:w-[480px] gap-[64px] flex-col flex justify-center items-center ">
          <div className="flex-col flex gap-[12px] items-center justify-start">
            <h4 className=" text-center text-blue-600 text-base font-semibold  leading-normal">Forgot Password</h4>
            <h2 className=" text-center text-neutral-700 md:text-3xl 100:text-[28px] 100:leading-8 font-semibold  md:leading-10">Enter your email to reset your password.</h2>
          </div>
          <form className="flex-col w-full flex gap-[24px] justify-start items-start ">
            <TextInput placeholder={"Enter your email address"} label={"Email"} error={formik.errors.email} type="text" value={formik.values.email} onChange={formik.handleChange("email")} />
            {/* <Link className="w-full" to="/auth/otpforgotpassword:id/:token"> */}
            <div className="leading-[20px] cursor-pointer border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-7 py-4 w-full " onClick={formik.handleSubmit}>
              {isLoading ? "Loading..." : "Next"}
            </div>
            {/* </Link> */}
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
