import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import { ReactComponent as Back } from "../Images/back.svg";
import { ReactComponent as Pending } from "../Images/pending.svg";
import { ReactComponent as Completed } from "../Images/complete.svg";
import { ReactComponent as Reject } from "../Images/reject.svg";
import illustration from "../Images/verify.svg";
import userDet from "../Images/userDetails.png";
import pick from "../Images/file.svg";
import no from "../Images/no.svg";
import emailu from "../Images/email.svg";
import user from "../Images/user.svg";
import map from "../Images/map.svg";
import linkedin from "../Images/linkedin.svg";
import x from "../Images/x.svg";
import Instagram from "../Images/instagram.svg";
import facebook from "../Images/facebook.svg";
import confirm from "../Images/Confirm.svg";
import cancel from "../Images/Cancel.svg";
import { useParams } from "react-router-dom";
import AdminRequest from "../../utils/services/adminRequests";
import { useFormik } from "formik";
import { approveUserValidator } from "../../utils/services/validation";
import TextArea from "../shared/input/TextArea";
import { toast } from "react-toastify";
import { formatDate, CurrencyFormatter } from "../../utils/helpers/helper";
import more from "../Images/more.svg";
import { ReactComponent as Up } from "../Images/arrowUpSharp.svg";
import { ReactComponent as Down } from "../Images/arrowDown.svg";
import BasicInfo from "./UserDetails/BasicInfo";
import IdentityVerification from "./UserDetails/IdentityVerification";
import Education from "./UserDetails/Education";
import EmploymentHistory from "./UserDetails/EmploymentHistory";
import FinancialSummary from "./UserDetails/FinancialSummary";
function UserDetails() {
  const { email } = useParams();

  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [isLoading, setLoading] = useState("");
  const [isUser, setUser] = useState({});
  const [failedArray, setFailedArray] = useState([]);
  const navigate = useNavigate();
  const [stages, setStages] = useState("basic-information");
  const [isTimeStampVisible, setIsTimeStampVisible] = useState(true);
  const [isBasicInfoVisible, setIsBasicInfoVisible] = useState(true);
  const [isCreditScoreVisible, setIsCreditScoreVisible] = useState(true);
  const [isSocialVisible, setIsSocialVisible] = useState(true);

  const toggleStages = (menu) => {
    setStages(menu);
  };
  const getUserDetails = async () => {
    await AdminRequest.getUserDetails(email).then((res) => {
      setUser(res.data.result);
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const formik = useFormik({
    validationSchema: approveUserValidator,
    initialValues: {
      comment: "",
    },
  });

  const declineRequest = async () => {
    setLoading(true);
    await AdminRequest.rejectRequest(
      email,
      formik.values.comment,
      failedArray
    ).then((res) => {
      setLoading(false);

      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setReject(true);
      } else {
        setLoading(false);
        toast.error(res?.data?.message || res?.message, {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    });
  };
  const approveRequest = async () => {
    setLoading(true);
    await AdminRequest.approveRequest(email, formik.values.comment).then(
      (res) => {
        setLoading(false);

        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setAccept(true);
        } else {
          setLoading(false);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      }
    );
  };

  console.log("isUser",  isUser?.creditHistory?.noRecord );

  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex items-center 100:w-[85%] lg:w-full gap-[32px]  justify-center flex-col">
          <div className="flex flex-col items-start justify-start gap-[20px] w-full">
            <h3 className="self-stretch flex justify-start items-center gap-[16px] text-[#3a3c3f] text-base font-medium  leading-normal ">
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="text-[#aab0b6] text-sm font-medium leading-normal justify-start items-center gap-1 inline-flex"
                to="/approver/user-management"
              >
                User Management{" "}
                <Back className=" w-[14px] h-[14px] rotate-180" />
              </Link>
              User Details
            </h3>
            <div className="lg:h-[208px] 100:h-full w-full px-8 py-6 bg-[#d5efff] rounded-2xl shadow 100:flex-col md:flex-row 100:gap-8 lg:gap-0 justify-between items-start inline-flex">
              <div className=" 100:w-full  lg:w-[621px]  flex-col justify-start items-start gap-3 inline-flex">
                <h3 className="self-stretch text-[#3a3c3f] 100:text-base sm:text-xl font-medium font-inter leading-normal">
                  Verify User Registration
                </h3>
                <p className="self-stretch text-[#565c64] 100:text-sm  sm:text-base font-normal font-inter leading-normal">
                  Review the user’s details and ensure all documents, such as ID
                  and proof of address, are valid and up-to-date. Confirm that
                  all required information is accurate before completing the
                  verification process.{" "}
                </p>
              </div>
              <div className="100:w-full md:w-fit">
                <img src={illustration} alt="illustration" />
              </div>
            </div>
            <div className="w-full flex flex-col 100:overflow-x-scroll md:overflow-hidden justify-start border-b-[#cdd1d4] border-b-[1px]">
              <div className="md:w-fit list-decimal  100:w-full whitespace-nowrap text-[#6c757d] text-sm font-medium  leading-tight flex justify-start items-center gap-[16px]">
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${
                    stages === "basic-information"
                      ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                      : ""
                  }`}
                  onClick={() => toggleStages("basic-information")}
                >
                  1. Basic Information
                  {isUser?.acceptanceStatus === "ACCEPT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : isUser?.acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )}
                </button>
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${
                    stages === "identity-verification"
                      ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                      : ""
                  }`}
                  onClick={() => toggleStages("identity-verification")}
                >
                  2. Identity Verification
                  {isUser?.acceptanceStatus === "ACCEPT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : isUser?.acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )}
                </button>
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${
                    stages === "education"
                      ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                      : ""
                  }`}
                  onClick={() => toggleStages("education")}
                >
                  3. Education
                  {isUser?.acceptanceStatus === "ACCEPT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : isUser?.acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )}
                </button>
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${
                    stages === "employment-history"
                      ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                      : ""
                  }`}
                  onClick={() => toggleStages("employment-history")}
                >
                  4. Employment History
                  {isUser?.acceptanceStatus === "ACCEPT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : isUser?.acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )}
                </button>
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${
                    stages === "financial-summary"
                      ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                      : ""
                  }`}
                  onClick={() => toggleStages("financial-summary")}
                >
                  5. Financial Summary
                  {isUser?.acceptanceStatus === "ACCEPT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : isUser?.acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="pb-6 w-full 100:flex-col md:flex-row flex justify-start items-start gap-4">
            <div className="  w-full">
              {stages === "basic-information" && <BasicInfo data={isUser} />}
              {stages === "identity-verification" && (
                <IdentityVerification data={isUser} />
              )}
              {stages === "education" && <Education data={isUser} />}
              {stages === "employment-history" && (
                <EmploymentHistory data={isUser} />
              )}
              {stages === "financial-summary" && (
                <FinancialSummary data={isUser} />
              )}
            </div>
            <div className="h-fit  100:w-full md:w-[378px]   p-4  rounded-lg border border-[#cdd1d4] bg-white flex-col justify-start items-center gap-4 inline-flex">
              <div className="h-fit w-full flex-col justify-start items-start gap-2 inline-flex">
                {/* BVN VERIFY */}
                <div className="h-fit w-full px-3 py-2 bg-[#f5f6f6] rounded-lg justify-start items-center gap-2 inline-flex">
                  <div className="h-[24px] w-[24px] mix-blend-multiply   bg-[#d0fadf]  rounded-2xl justify-center items-center flex">
                    {" "}
                    <Completed />{" "}
                  </div>
                  <div className="w-fit flex-col justify-start items-start gap-1 inline-flex">
                    <h4 className=" text-[#6c757d] text-xs font-normal font-inter leading-[20px]">
                      Bank Verification Number (BVN)
                    </h4>
                    <h3 className=" text-[#4a4f54] text-sm font-medium font-inter leading-[145%]">
                      Verified
                    </h3>
                  </div>
                </div>
                {/* NIN VERIFY */}
                {/* <div className="h-fit w-full px-3 py-2 bg-[#f5f6f6] rounded-lg justify-start items-center gap-2 inline-flex">
                  <div className="h-[24px] w-[24px] mix-blend-multiply   bg-[#ffffea]  rounded-2xl justify-center items-center flex">
                    {" "}
                    <Pending />
                  </div>
                  <div className="w-fit  flex-col justify-start items-start gap-1 inline-flex">
                    <h4 className=" text-[#6c757d] text-xs font-normal font-inter leading-[20px]">National Identity Number (NIN)</h4>
                    <h3 className=" text-[#4a4f54] text-sm font-medium font-inter leading-[145%]">Unverified</h3>
                  </div>
                </div> */}
              </div>
              {/* Time Stamp Section */}
              <div className="h-fit w-full px-3 py-2 bg-[#f5f6f6] rounded-lg flex-col justify-start items-start gap-4 inline-flex">
                <div className="w-full justify-start items-start gap-1 inline-flex">
                  <div>
                    <img src={more} alt="more" />
                  </div>
                  <h5 className="text-[#6c757d] w-full text-sm font-normal font-inter leading-tight">
                    Time Stamp
                  </h5>
                  <div
                    className="cursor-pointer"
                    onClick={() => setIsTimeStampVisible((prev) => !prev)}
                  >
                    {isTimeStampVisible ? <Up /> : <Down />}
                  </div>
                </div>
                {isTimeStampVisible && (
                  <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                    <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                      <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                        Date Submitted
                      </h4>
                      <h3 className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight">
                        {formatDate(new Date(isUser.createdAt))}
                      </h3>
                    </div>
                    <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                      <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                        Time Initiated
                      </h4>
                      <h3 className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight">
                        {new Date(isUser.createdAt).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          }
                        )}
                      </h3>
                    </div>
                  </div>
                )}
              </div>
              {/* Basic Information */}
              <div className="h-fit w-full px-3 py-2 bg-[#f5f6f6] rounded-lg flex-col justify-start items-start gap-4 inline-flex">
                <div className="w-full justify-start items-start gap-1 inline-flex">
                  <div>
                    <img src={more} alt="more" />
                  </div>
                  <h5 className="text-[#6c757d] w-full text-sm font-normal font-inter leading-tight">
                    Basic Information
                  </h5>
                  <div
                    className="cursor-pointer"
                    onClick={() => setIsBasicInfoVisible((prev) => !prev)}
                  >
                    {isBasicInfoVisible ? <Up /> : <Down />}
                  </div>
                </div>
                {isBasicInfoVisible && (
                  <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                    <div className="w-full px-3 py-1 bg-white rounded-lg justify-start items-center gap-3 inline-flex">
                      <img src={no} alt="" />{" "}
                      <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">
                        {isUser.id}
                      </h4>
                    </div>
                    <div className="w-full px-3 py-1 bg-white rounded-lg justify-start items-center gap-3 inline-flex">
                      <img src={emailu} alt="" />{" "}
                      <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">
                        {isUser.email}
                      </h4>
                    </div>
                    <div className="w-full px-3 py-1 bg-white rounded-lg justify-start items-center gap-3 inline-flex">
                      <img src={user} alt="" />{" "}
                      <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">
                        {" "}
                        {isUser?.firstName} {isUser?.lastName}
                      </h4>
                    </div>
                    <div className="w-full px-3 py-1 bg-white rounded-lg justify-start items-center gap-3 inline-flex">
                      <img src={map} alt="" />{" "}
                      <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">
                        {" "}
                        {isUser?.address ? isUser?.address : "No data yet."}
                      </h4>
                    </div>
                  </div>
                )}
              </div>
              {/* Credit Score */}
              <div className="h-fit w-full px-3 py-2 bg-[#f5f6f6] rounded-lg flex-col justify-start items-start gap-4 inline-flex">
                <div className="w-full justify-start items-start gap-1 inline-flex">
                  <div>
                    <img src={more} alt="more" />
                  </div>
                  <h5 className="text-[#6c757d] w-full text-sm font-normal font-inter leading-tight">
                    Credit Score
                  </h5>
                  <div
                    className="cursor-pointer"
                    onClick={() => setIsCreditScoreVisible((prev) => !prev)}
                  >
                    {isCreditScoreVisible ? <Up /> : <Down />}
                  </div>
                </div>
                {isUser?.creditHistory?.noRecord  || isUser?.creditHistory  === undefined
                  ? isCreditScoreVisible && (
                      <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                        <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                          <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                            No Credit History Found
                          </h4>
                          <h3 className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight"></h3>
                        </div>
                      </div>
                    )
                  : isCreditScoreVisible && (
                      <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                        <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                          <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                            CRC Report Order Number
                          </h4>
                          <h3 className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight">
                            {isUser?.creditHistory?.reportOrderNumber || ""}
                          </h3>
                        </div>
                        <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                          <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                            Last Reported Date
                          </h4>
                          <h3 className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight">
                            {formatDate(
                              new Date(isUser?.creditHistory?.reportOrderDate)
                            )}
                          </h3>
                        </div>
                        <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                          <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                            Credit Rating
                          </h4>
                          <h3 className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight">
                            {isUser?.creditHistory?.creditRating || "N/A"}
                          </h3>
                        </div>
                        <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                          <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                            Score
                          </h4>
                          <h3 className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight">
                            {isUser?.creditHistory?.creditScore || "N/A"} / 850
                          </h3>
                        </div>

                        <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                          <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                            Score Reason
                          </h4>
                          <h3 className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight">
                            {isUser?.creditHistory?.reasonCodes || "N/A"}
                          </h3>
                        </div>
                        <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                          <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                            No of Loan Facilities
                          </h4>
                          <h3 className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight">
                            {isUser?.creditHistory?.performanceSummary.length ||
                              0}
                          </h3>
                        </div>

                        <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                          <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                            Dishonored Cheques
                          </h4>
                          <h3 className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight">
                            {isUser?.creditHistory?.dishonoredChequesCount || 0}
                          </h3>
                        </div>
                        <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                          <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                            Facilities
                          </h4>
                          {isUser?.creditHistory?.performanceSummary.map(
                            (entry, index) => (
                              <h3
                                key={index}
                                className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight mb-5"
                              >
                                {entry}
                              </h3>
                            )
                          )}
                        </div>

                        <div className="w-full px-3 py-1 bg-white rounded-lg flex-col justify-start items-start gap-1 flex">
                          <h4 className="self-stretch text-[#4a4f54] text-[10px] font-normal font-inter leading-[14.50px]">
                            Profile Overview
                          </h4>
                          {isUser?.creditHistory?.creditProfileOverview.map(
                            (entry, index) => (
                              <h3
                                key={index}
                                className="self-stretch text-[#4a4f54] text-sm font-medium font-inter leading-tight mb-5"
                              >
                                {entry}
                              </h3>
                            )
                          )}
                        </div>
                      </div>
                    )}
              </div>
              {/* Socials */}
              <div className="h-fit w-full px-3 py-2 bg-[#f5f6f6] rounded-lg flex-col justify-start items-start gap-4 inline-flex">
                <div className="w-full justify-start items-start gap-1 inline-flex">
                  <div>
                    <img src={more} alt="more" />
                  </div>
                  <h5 className="text-[#6c757d] w-full text-sm font-normal font-inter leading-tight">
                    Socials
                  </h5>
                  <div
                    className="cursor-pointer"
                    onClick={() => setIsSocialVisible((prev) => !prev)}
                  >
                    {isSocialVisible ? <Up /> : <Down />}
                  </div>
                </div>
                {isSocialVisible && (
                  <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                    <div className="w-full px-3 py-1 bg-white rounded-lg justify-start items-center gap-3 inline-flex">
                      <img src={linkedin} alt="" />{" "}
                      {isUser?.educationInformation?.linkedInAddress ? (
                        <a
                          href={`${
                            isUser?.educationInformation?.linkedInAddress?.startsWith(
                              "http"
                            )
                              ? isUser.educationInformation.linkedInAddress
                              : `https://${isUser?.educationInformation?.linkedInAddress}`
                          }`}
                          className="truncate text-[#4a4f54] text-sm font-medium leading-tight"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {isUser?.educationInformation?.linkedInAddress}
                        </a>
                      ) : (
                        <h4 className="truncate text-[#4a4f54] text-sm font-medium leading-tight">
                          No data yet.
                        </h4>
                      )}
                    </div>
                    <div className="w-full px-3 py-1 bg-white rounded-lg justify-start items-center gap-3 inline-flex">
                      <img src={Instagram} alt="" />{" "}
                      {isUser.educationInformation?.instagramLink ? (
                        <a
                          href={`${
                            isUser?.educationInformation?.instagramLink?.startsWith(
                              "http"
                            )
                              ? isUser.educationInformation.instagramLink
                              : `https://${isUser?.educationInformation?.instagramLink}`
                          }`}
                          className="truncate text-[#4a4f54] text-sm font-medium leading-tight"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {isUser?.educationInformation?.instagramLink}
                        </a>
                      ) : (
                        <h4 className="truncate text-[#4a4f54] text-sm font-medium leading-tight">
                          No data yet.
                        </h4>
                      )}
                    </div>
                    <div className="w-full px-3 py-1 bg-white rounded-lg justify-start items-center gap-3 inline-flex">
                      <img alt="" src={x} />{" "}
                      {isUser?.educationInformation?.twitterLink ? (
                        <a
                          href={`${
                            isUser?.educationInformation?.twitterLink?.startsWith(
                              "http"
                            )
                              ? isUser.educationInformation.twitterLink
                              : `https://${isUser?.educationInformation?.twitterLink}`
                          }`}
                          className="truncate text-[#4a4f54] text-sm font-medium leading-tight"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {isUser?.educationInformation?.twitterLink}
                        </a>
                      ) : (
                        <h4 className="truncate text-[#4a4f54] text-sm font-medium leading-tight">
                          No data yet.
                        </h4>
                      )}
                    </div>
                    <div className="w-full px-3 py-1 bg-white rounded-lg justify-start items-center gap-3 inline-flex">
                      <img alt="" src={facebook} />{" "}
                      {isUser?.educationInformation?.facebookProfile ? (
                        <a
                          href={`${
                            isUser?.educationInformation?.facebookProfile?.startsWith(
                              "http"
                            )
                              ? isUser.educationInformation.facebookProfile
                              : `https://${isUser?.educationInformation?.facebookProfile}`
                          }`}
                          className="truncate text-[#4a4f54] text-sm font-medium leading-tight"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {isUser?.educationInformation?.facebookProfile}
                        </a>
                      ) : (
                        <h4 className="truncate text-[#4a4f54] text-sm font-medium leading-tight">
                          No data yet.
                        </h4>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex-col w-full border-t border-t-[#E5E7E8] pt-[16px] justify-start items-center gap-[16px] inline-flex">
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">
                    Status
                  </h3>
                  <div
                    className={`h-[22px] gap-[4px] mix-blend-multiply px-2 py-0.5 ${
                      isUser?.acceptanceStatus === "ACCEPT"
                        ? "bg-[#f1fcf2]"
                        : isUser?.acceptanceStatus === "REJECT"
                        ? "bg-red-100"
                        : "bg-[#ffffea]"
                    }  leading-[18px] ${
                      isUser?.acceptanceStatus === "ACCEPT"
                        ? "text-[#1f7634]"
                        : isUser?.acceptanceStatus === "REJECT"
                        ? "text-red-500"
                        : "text-[#bb6902]"
                    } text-center  text-xs font-medium rounded-2xl justify-center items-center flex`}
                  >
                    {" "}
                    {isUser?.acceptanceStatus === "ACCEPT" ? (
                      <Completed />
                    ) : isUser?.acceptanceStatus === "REJECT" ? (
                      <Reject />
                    ) : (
                      <Pending />
                    )}
                    {/* {isUser?.acceptanceStatus} */}
                    {isUser?.acceptanceStatus
                      ? isUser.acceptanceStatus.charAt(0).toUpperCase() +
                        isUser.acceptanceStatus.slice(1).toLowerCase()
                      : ""}
                    {/* Pending Approval */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {accept && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start isUsers-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div
                onClick={() => {
                  setAccept(false);
                }}
                className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
              >
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">
                        Approved Successfully
                      </h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">
                        This user has been approved successfully..
                      </p>
                    </div>
                  </div>
                  <div
                    className="w-full"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <div className="leading-[20px] cursor-pointer border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">
                      Back to Dashboard
                    </div>
                  </div>{" "}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
      {reject && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div
                onClick={() => setReject(false)}
                className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
              >
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">
                        Rejected Successfully
                      </h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">
                        This user has been rejected successfully..
                      </p>
                    </div>
                  </div>
                  <div
                    className="w-full"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">
                      Back to Dashboard
                    </div>
                  </div>{" "}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default UserDetails;
