import axios from "axios";
import { clearUser } from "../redux/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { store } from "../redux/store";
export const ApiClient = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Accept: "application/json",
    },
    timeout: 60000,
  });

  api.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("ijeToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  console.log("store.dispatch:", store.dispatch);

  async function refreshToken() {}
  function createAxiosResponseInterceptor() {
    const interceptor = api.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        console.log("erre", error.response.status);
        if (error.response.status === 403) {
          try {
            console.log("error, is heress", error.response.status);
            // api.interceptors.response.eject(interceptor);
            localStorage.removeItem("ijeDetails");
            store.dispatch(clearUser());
            // toast.success("Logged Out ", {
            //   position: "top-right",
            //   autoClose: 1000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "colored",
            // });
            toast.error("Session Expired", {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          } catch (err) {
            if (err.response && err.response.status === 400) {
              throw { response: { status: 401 } };
              //   await SecureStore.deleteItemAsync("accessToken");
              //   await SecureStore.deleteItemAsync("refreshToken");
              // Add logic to log the user out or redirect to login page
            }
            return Promise.reject(err);
          } finally {
            createAxiosResponseInterceptor();
          }
        }
        return Promise.reject(error);
      }
    );
  }

  createAxiosResponseInterceptor();

  const get = (path, params) => {
    return api.get(path, { params });
  };

  const post = (path, body) => {
    // console.log(body)
    return api.post(path, body);
  };

  const put = (path, body, params) => {
    return api.put(path, body, { params });
  };

  const patch = (path, body, params) => {
    return api.patch(path, body, { params });
  };

  const del = (path) => {
    return api.delete(path);
  };

  return {
    get,
    post,
    patch,
    put,
    del,
  };
};
