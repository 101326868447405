import { useAppDispatch, useAppSelector } from "../../../utils/redux/store";
import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Pending } from "../../Images/pending.svg";
import { ReactComponent as Reject } from "../../Images/reject.svg";
import edit from "../../Images/editReg.svg";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { currencyData } from "../../../utils/helpers/constants";
import { SaveCancelButton } from "./SaveCancelButton";
import { EditButton } from "./EditButton";
import TextInput from "../../shared/input/TextInput";
import { CurrencyFormatter } from "../../../utils/helpers/helper";
import { toast } from "react-toastify";
import EducationRequest from "../../../utils/services/educationRequests";
import UserRequest from "../../../utils/services/userRequets";
import { setUser } from "../../../utils/redux/authSlice";

function FinancialSummary() {
  const { email, acceptanceStatus, educationInformation } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();
  const [currencyEdit, setCurrencyEdit] = useState(false);
  const [tuitionEdit, setTuitionEdit] = useState(false);
  const [expenseEdit, setExpenseEdit] = useState(false);
  const [availableEdit, setAvailableEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      currency: educationInformation?.currency,
      tuitionFee: educationInformation?.tuitionFee,
      livingExpenses: educationInformation?.livingExpenses,
      totalFundingRequirement: educationInformation?.totalFundingRequirement,
      amountAvailable: educationInformation?.amountAvailable,
    },
    onSubmit: async (values) => {
      const payload = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== formik.initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      setLoading(true);
      await EducationRequest.registerEducationedit(payload)
        .then(async (res) => {
          setLoading(false);
          if (res.data.statusCode === 200) {
            await UserRequest.getUserDetials(email).then((res) => {
              if (res.data.statusCode === 200) {
                localStorage.setItem(
                  "ijeDetails",
                  JSON.stringify(res.data.result)
                );
                dispatch(setUser({ ...res.data.result }));

                toast.success("Information Updated", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                setCurrencyEdit(false);
                setTuitionEdit(false);
                setExpenseEdit(false);
                setAvailableEdit(false);
              } else {
                toast.error(res?.data?.message || res?.message, {
                  position: "top-right",
                  autoClose: 2000,
                  theme: "colored",
                });
              }
            });
          } else {
            setLoading(false);

            toast.error(res?.data?.message || res?.message, {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          }

          // setPage(page + 1)
        })
        .finally(()=>{
          setLoading(false)
        })
    },
  });

  useEffect(() => {
    const total =
      parseFloat(formik.values.tuitionFee) +
      parseFloat(formik.values.livingExpenses);
    formik.setFieldValue("totalFundingRequirement", total);
  }, [formik.values.tuitionFee, formik.values.livingExpenses]);

  return (
    <>
      <div className=" w-full p-6 bg-white flex-col rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-[16px] inline-flex">
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Currency
                </h4>

                {currencyEdit ? (
                  <label
                    className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                    htmlFor=" Currency"
                  >
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select currency"
                      // options={currency}
                      options={currencyData}
                      onChange={(e) => {
                        formik.setFieldValue("currency", e.value);
                        formik.setFieldValue("currency", e.value);
                      }}
                    />
                  </label>
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {educationInformation?.currency}
                  </h3>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              currencyEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setCurrencyEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setCurrencyEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Tuition Fee
                </h4>

                {tuitionEdit ? (
                  <TextInput
                    label={""}
                    type="text"
                    value={formik.values.tuitionFee}
                    onChange={formik.handleChange("tuitionFee")}
                    onBlur={formik.handleBlur("tuitionFee")}
                    placeholder="Tuition Fee"
                    error={
                      formik.touched.tuitionFee && formik.errors.tuitionFee
                    }
                  />
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {CurrencyFormatter(
                      parseFloat(educationInformation?.tuitionFee || 0),
                      "$"
                    )}
                  </h3>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              tuitionEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setTuitionEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setTuitionEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Living Expenses
                </h4>
                {expenseEdit ? (
                  <TextInput
                    label={``}
                    type="text"
                    value={formik.values.livingExpenses}
                    onChange={formik.handleChange("livingExpenses")}
                    onBlur={formik.handleBlur("livingExpenses")}
                    placeholder="Living expenses"
                    error={
                      formik.touched.livingExpenses &&
                      formik.errors.livingExpenses
                    }
                  />
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {CurrencyFormatter(
                      parseFloat(educationInformation?.livingExpenses || 0),
                      "$"
                    )}
                  </h3>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              expenseEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setExpenseEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setExpenseEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Total Funding Requirement{" "}
                </h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                  {CurrencyFormatter(
                    parseFloat(formik.values.totalFundingRequirement || 0),
                    "$"
                  )}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Amount available{" "}
                </h4>
                {availableEdit ? (
                  <TextInput
                    label={``}
                    type="text"
                    value={formik.values.amountAvailable}
                    onChange={formik.handleChange("amountAvailable")}
                    onBlur={formik.handleBlur("amountAvailable")}
                    placeholder="Amount Available"
                    error={
                      formik.touched.amountAvailable &&
                      formik.errors.amountAvailable
                    }
                  />
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {CurrencyFormatter(
                      parseFloat(educationInformation?.amountAvailable || 0),
                      "$"
                    )}
                  </h3>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              availableEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setAvailableEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setAvailableEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Status
              </h4>

              {acceptanceStatus === "PENDING" ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                  <Pending /> Pending Approval
                </div>
              ) : educationInformation?.stage === 4 &&
                !educationInformation?.failedPages?.includes(4) ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                  {" "}
                  <Completed /> Completed
                </div>
              ) : educationInformation?.failedPages?.includes(4) ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                  <Reject /> Rejected
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinancialSummary;
