import { useState } from "react";
import SideBar from "./SideBar";
import PersonalInfo from "./Settings/PersonalInfo";
import AccountSecurity from "./Settings/AccountSecurity";
import NotificationPreference from "./Settings/NotificationPreference";
import PrivacySetting from "./Settings/PrivacySetting";
import LinkedAccouunt from "./Settings/LinkedAccouunt";

function Settings() {
  const [setting, setSetting] = useState("personal-information");

  const handleToggleSettings = (menu) => {
    setSetting(menu);
  };
  return (
    <>
      <SideBar />
      <div className=" pb-[50px] flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className="flex flex-col items-start justify-start gap-[32px] w-full">
            <h3 className="text-[#3a3c3f] text-2xl font-medium leading-[38px]">Settings </h3>
            <div className="w-full flex flex-col 100:overflow-x-scroll md:overflow-hidden justify-start border-b-[#cdd1d4] border-b-[1px]">
              <div className="md:w-fit  100:w-full whitespace-nowrap text-[#6c757d] text-sm font-medium  leading-tight flex justify-start items-center gap-[16px]">
                <button className={`h-[38px] px-[4px] pb-[8px] ${setting === "personal-information" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => handleToggleSettings("personal-information")}>
                  Personal Information
                </button>
                <button className={`h-[38px] px-[4px] pb-[8px] ${setting === "account-security" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => handleToggleSettings("account-security")}>
                  Account Security
                </button>
                <button className={`h-[38px] px-[4px] pb-[8px] ${setting === "notification-preferences" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => handleToggleSettings("notification-preferences")}>
                  Notification Preferences
                </button>{" "}
                <button className={`h-[38px] px-[4px] pb-[8px] ${setting === "linked-accounts" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => handleToggleSettings("linked-accounts")}>
                  Linked Accounts
                </button>{" "}
                <button className={`h-[38px] px-[4px] pb-[8px] ${setting === "privacy-settings" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => handleToggleSettings("privacy-settings")}>
                  Privacy Settings
                </button>{" "}
              </div>
            </div>
            <div className=" w-full">
              {setting === "personal-information" && <PersonalInfo />}
              {setting === "account-security" && <AccountSecurity />}
              {setting === "notification-preferences" && <NotificationPreference />}
              {setting === "linked-accounts" && <LinkedAccouunt />}
              {setting === "privacy-settings" && <PrivacySetting />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
