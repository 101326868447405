import SideBar from "./SideBar";
import { ReactComponent as Icon } from "../Images/documentation.svg";
import { ReactComponent as Icon2 } from "../Images/contact.svg";

function Support() {
  return (
    <>
      <SideBar />
      <div className=" pb-[50px] flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[362px]">
        <div className="flex gap-[40px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className="w-full md:px-8 flex-col justify-start items-start gap-8 inline-flex">
            <div className="w-full flex-col justify-start items-start gap-[8px] inline-flex">
              <h3 className="self-stretch text-[#3a3c3f] text-2xl font-medium  leading-[38px]">Support</h3>
              <p className="md:w-[545px] 100:w-full text-[#6c757d] text-base font-normal  leading-snug">Find answers to common questions, access our documentation, or contact our support team for assistance.</p>
            </div>
            {/* <div className="w-full justify-start items-start gap-4 inline-flex">
              <div style={{ color: "#0C4AFF", backgroundColor: "#d5efff", borderColor: "#E8F7FF" }} className={`w-12 h-12  flex justify-center items-center rounded-[28px]  border-4 `}>
                <Icon />
              </div>
              <div className="w-full pt-2.5 flex-col justify-start items-start gap-2 inline-flex">
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium  leading-[30px]">Documentation & Resources</h3>
                <div className="w-full text-[#6c757d] text-sm font-normal  underline leading-[14px]">
                  User Guide
                  <br />
                  <br />
                  Video Tutorials
                  <br />
                  <br />
                  Knowledge Base
                </div>
              </div>
            </div> */}
            <div className="w-full justify-start items-start gap-4 inline-flex">
              <div style={{ color: "#0C4AFF", backgroundColor: "#d5efff", borderColor: "#E8F7FF" }} className={`w-12 h-12  flex justify-center items-center rounded-[28px]  border-4 `}>
                <Icon2 />
              </div>
              <div className="w-full pt-2.5 flex-col justify-start items-start gap-2 inline-flex">
                <div className="self-stretch text-[#3a3c3f] text-base font-medium  leading-[30px]">Contact Support</div>
                <div className="w-full text-[#6c757d] text-sm font-normal  leading-[14px]">
                  <span className=" flex justify-start items-center gap-[4px]">
                    Email:
                    <a href="mailto:hello@ijefund.com">
                      <span className="text-[#0c4aff] ">hello@ijefund.com</span>
                    </a>
                  </span>
                  <br />

                  <span className=" flex justify-start items-center gap-[4px]">
                    Phone:
                    <a href="tel:+2348163153270">
                      {" "}
                      <span className="text-[#0c4aff] ">+234 816 315 3270</span>
                    </a>
                  </span>
                  <br />
                  <span className=" flex justify-start items-center gap-[4px]">
                    Whatsapp:
                    <a href="https://wa.me/+2348163153270">
                      {" "}
                      <span className="text-[#0c4aff] ">+234 816 315 3270</span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full   md:px-8 flex-col justify-start items-start gap-8 inline-flex">
            <div className="w-full flex-col justify-start items-start gap-[8px] inline-flex">
              <h3 className="self-stretch text-[#3a3c3f] text-[20px] font-medium  leading-[25px]">Frequently Asked Questions</h3>
              <p className="w-full text-[#6c757d] text-[14px] font-normal  leading-snug">Quickly find answers to the most commonly asked questions.</p>
            </div>
            <div className="w-full flex-col justify-start items-start gap-[8px] inline-flex">
              <h3 className="self-stretch text-[#3a3c3f] text-[16px] font-medium  leading-[23px]">How do I reset my password?</h3>
              <p className="text-[#6c757d] w-full text-sm   font-normal leading-normal ">
                Click on the <span className="text-[#4a4f54] font-medium"> 'Forgot Password' </span> link on the login page and follow the instructions.
              </p>{" "}
            </div>
            <div className="w-full flex-col justify-start items-start gap-[8px] inline-flex">
              <h3 className="self-stretch text-[#3a3c3f] text-[16px] font-medium  leading-[23px]">How do I add a new user?</h3>
              <p className="text-[#6c757d] w-full text-sm   font-normal leading-normal ">
                Navigate to the <span className="text-[#4a4f54] font-medium"> 'Users' </span> section and click on <span className="text-[#4a4f54] font-medium"> 'Add Users' </span>Fill in the required details and submit.
              </p>{" "}
            </div>
            <div className="w-full flex-col justify-start items-start gap-[8px] inline-flex">
              <h3 className="self-stretch text-[#3a3c3f] text-[16px] font-medium  leading-[23px]">How do I generate reports?</h3>
              <p className="text-[#6c757d] w-full text-sm   font-normal leading-normal ">
                Go to the <span className="text-[#4a4f54] font-medium"> 'Reports' </span> section, select the type of report, date range and data fields, then click <span className="text-[#4a4f54] font-medium"> 'General Report' </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;
