import { useAppDispatch, useAppSelector } from "../../../utils/redux/store";
import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Pending } from "../../Images/pending.svg";
import { ReactComponent as Reject } from "../../Images/reject.svg";
import edit from "../../Images/editReg.svg";
import pick from "../../Images/file.svg";
import { useState } from "react";
import { useFormik } from "formik";
import {
  employmentMonths,
  employmentStatusData,
  yearsofExperienceData,
} from "../../../utils/helpers/constants";
import Select from "react-select";
import { SaveCancelButton } from "./SaveCancelButton";
import { EditButton } from "./EditButton";
import TextInput from "../../shared/input/TextInput";
import FileInput from "../../shared/input/FileInput";
import { toast } from "react-toastify";
import EducationRequest from "../../../utils/services/educationRequests";
import UserRequest from "../../../utils/services/userRequets";
import { setUser } from "../../../utils/redux/authSlice";

function EmploymentHistory() {
  const { email, acceptanceStatus, educationInformation } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();
  const [employmentEdit, setEmploymentEdit] = useState(false);
  const [experienceEdit, setExperienceEdit] = useState(false);
  const [employerEdit, setEmployerEdit] = useState(false);
  const [roleEdit, setRoleEdit] = useState(false);
  const [monthsEdit, setMonthsEdit] = useState(false);
  const [letterEdit, setLetterEdit] = useState(false);
  const [statementEdit, setStatementEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      employmentStatus: educationInformation?.employmentStatus,
      yearsOfWorkExperience: educationInformation?.yearsOfWorkExperience,
      currentEmployer: educationInformation?.currentEmployer,
      role: educationInformation?.role,
      monthsWithEmployer: educationInformation?.monthsWithEmployer,
      employmentLetter: educationInformation?.employmentLetter,
      bankStatement: educationInformation?.bankStatement,
    },
    onSubmit: async (values) => {
      const payload = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== formik.initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      setLoading(true);
      await EducationRequest.registerEducationedit(payload)
        .then(async (res) => {
          setLoading(false);
          if (res.data.statusCode === 200) {
            await UserRequest.getUserDetials(email).then((res) => {
              if (res.data.statusCode === 200) {
                localStorage.setItem(
                  "ijeDetails",
                  JSON.stringify(res.data.result)
                );
                dispatch(setUser({ ...res.data.result }));

                toast.success("Information Updated", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                setEmploymentEdit(false);
                setExperienceEdit(false);
                setEmployerEdit(false);
                setRoleEdit(false);
                setMonthsEdit(false);
                setLetterEdit(false);
                setStatementEdit(false);
              } else {
                toast.error(res?.data?.message || res?.message, {
                  position: "top-right",
                  autoClose: 2000,
                  theme: "colored",
                });
              }
            });
          } else {
            setLoading(false);

            toast.error(res?.data?.message || res?.message, {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          }

          // setPage(page + 1)
        })
        .finally(()=>{
          setLoading(false)
        })
    },
  });

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix
        formik.setFieldValue(name, base64String);
        // console.log(name, formik.values);
      };
      reader.readAsDataURL(files[0]);
    }
  };
  return (
    <>
      <div className=" w-full p-6 bg-white flex-col rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-[16px] inline-flex">
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Employment Status
                </h4>

                {employmentEdit ? (
                  <label
                    className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                    htmlFor="employmentStatus"
                  >
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select Status"
                      // options={currency}
                      options={employmentStatusData}
                      onChange={(e) => {
                        formik.setFieldValue("employmentStatus", e.value);
                        formik.setFieldValue("employmentStatus", e.value);
                      }}
                    />
                  </label>
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {educationInformation?.employmentStatus}
                  </h3>
                )}
              </div>

              {acceptanceStatus === "ACCEPT" ? null :
              employmentEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setEmploymentEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setEmploymentEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Years of Work Experience
                </h4>
                {experienceEdit ? (
                  <label
                    className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                    htmlFor="yearsOfWorkExperience"
                  >
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select"
                      // options={currency}
                      options={yearsofExperienceData}
                      onChange={(e) => {
                        formik.setFieldValue("yearsOfWorkExperience", e.value);
                        formik.setFieldValue("yearsOfWorkExperience", e.value);
                      }}
                    />
                  </label>
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {educationInformation?.yearsOfWorkExperience}
                  </h3>
                )}
              </div>

              {acceptanceStatus === "ACCEPT" ? null :
              experienceEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setExperienceEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setExperienceEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Name of Current Employer
                </h4>

                {employerEdit ? (
                  <TextInput
                    label={""}
                    type="text"
                    value={formik.values.currentEmployer}
                    onChange={formik.handleChange("currentEmployer")}
                    onBlur={formik.handleBlur("currentEmployer")}
                    placeholder="Name of Current Employer"
                    error={
                      formik.touched.currentEmployer &&
                      formik.errors.currentEmployer
                    }
                  />
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {educationInformation?.currentEmployer}
                  </h3>
                )}
              </div>

              {acceptanceStatus === "ACCEPT" ? null :
              employerEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setEmployerEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setEmployerEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Role
                </h4>
                {roleEdit ? (
                  <TextInput
                    label={""}
                    type="text"
                    value={formik.values.role}
                    onChange={formik.handleChange("role")}
                    onBlur={formik.handleBlur("role")}
                    placeholder="Role"
                    error={formik.touched.role && formik.errors.role}
                  />
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {educationInformation?.role}
                  </h3>
                )}
              </div>

              {acceptanceStatus === "ACCEPT" ? null :
              roleEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setRoleEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setRoleEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Number of Months with Employer
                </h4>
                {monthsEdit ? (
                  <label
                    className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                    htmlFor="monthsWithEmployer"
                  >
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select"
                      // options={currency}
                      options={employmentMonths}
                      onChange={(e) => {
                        formik.setFieldValue("monthsWithEmployer", e.value);
                        formik.setFieldValue("monthsWithEmployer", e.value);
                      }}
                    />
                  </label>
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {educationInformation?.monthsWithEmployer}
                  </h3>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              monthsEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setMonthsEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setMonthsEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Employment Letter
                </h4>

                {letterEdit ? (
                  <FileInput
                    label={"Upload Employment Letter"}
                    error={formik.errors.employmentLetter}
                    onChange={handleFileChange}
                    name="employmentLetter"
                  />
                ) : (
                  <div className=" gap-4 w-full justify-start items-center  inline-flex">
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className="gap-[4px] w-full flex-col  justify-start items-start inline-flex">
                      <h4 className="text-[#3a3c3f] text-base font-medium font-inter  leading-tight">
                        Employment Letter
                      </h4>
                      <h5 className="text-[#6c757d] text-xs font-normal eading-tight">
                        200 KB
                      </h5>
                    </div>
                  </div>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              letterEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setLetterEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setLetterEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  12 Months Bank Statement
                </h4>

                {statementEdit ? (
                  <FileInput
                    label={"Upload 12 Months Bank Statement"}
                    error={formik.errors.bankStatement}
                    onChange={handleFileChange}
                    name="bankStatement"
                  />
                ) : (
                  <div className=" gap-4 w-full justify-start items-center  inline-flex">
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className="gap-[4px] w-full flex-col  justify-start items-start inline-flex">
                      <h4 className="text-[#3a3c3f] text-base font-medium font-inter  leading-tight">
                        12 Months Bank Statement
                      </h4>
                      <h5 className="text-[#6c757d] text-xs font-normal eading-tight">
                        200 KB
                      </h5>
                    </div>
                  </div>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              statementEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setStatementEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setStatementEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Status
              </h4>

              {acceptanceStatus === "PENDING" ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                  <Pending /> Pending Approval
                </div>
              ) : educationInformation?.stage > 3 &&
                !educationInformation?.failedPages?.includes(3) ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                  {" "}
                  <Completed /> Completed
                </div>
              ) : educationInformation?.failedPages?.includes(3) ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                  <Reject /> Rejected
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmploymentHistory;
