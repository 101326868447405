import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../Images/back.svg";
import logo from "../Images/Logo.svg";
import open from "../Images/open.svg";
import viewOff from "../Images/viewOff.svg";
import unticked from "../Images/unTicked.svg";
import ticked from "../Images/ticked.svg";
import cancel from "../Images/Cancel.svg";
import confirm from "../Images/Confirm.svg";
import { useFormik } from "formik";
import AuthRequest from "../../utils/services/authRequests";
import { toast } from "react-toastify";
import TextInput from "../shared/input/TextInput";

function CreatePassword() {
  const [view, setView] = useState(false);
  const [view1, setView1] = useState(false);
  const [modal, setModal] = useState(false);
  const [navBar, setNavBar] = useState(false);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [validFormValues, setValidFormValues] = useState({
    length: false,
    numbers: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
  });
  const formik = useFormik({
    initialValues: {
      createPassword: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      // navigate("/auth/otp:id/:token");
      setLoading(true);
      // console.log("Form submitted with values:", values);
      await AuthRequest.resetPassword(values.createPassword, values.confirmPassword).then((res) => {
        setLoading(false);

        // navigate("/auth/otp:id/:token");
        if (res.data.statusCode === 200) {
          // localStorage.setItem('emailData', values.email);
          // setModal(true);
          // setSuccess(true);
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // navigate("/auth/login");
          setModal(true);
        } else {
          // setErrorMessage(res.data.message);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
          setSuccess(false);
        }
      });
    },
    // enableReinitialize: true,
  });
  const updateValidationStatus = () => {
    const { createPassword } = formik.values;

    setValidFormValues({
      length: createPassword.length >= 8,
      uppercase: /[A-Z]/.test(createPassword),
      lowercase: /[a-z]/.test(createPassword),
      specialChar: /[@#$%^&*()._-]/.test(createPassword),
      numbers: /[0-9]/.test(createPassword),
    });
  };
  useEffect(() => {
    updateValidationStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);
  return (
    <>
      <div className=" pb-[50px] bg-white flex-col h-screen w-full gap-[100px]  items-center flex justify-start  ">
        <div className="fixed  top-0 z-20 w-full flex items-center p-[10px] justify-center">
          <div className={` rounded-[50px]   px-[30px] my-[10px] ${navBar ? "bg-[#D5EFFF] " : ""} h-[75px] flex justify-between items-center w-[90%] `}>
            <Link to={"/auth/login"}>
              <div className="text-[#0C4AFF] gap-[8px] flex justify-start items-center ">
                <Back />
                <h4 className="  text-[16px] font-medium leading-[24px] ">Back</h4>
              </div>
            </Link>
            <div>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/"
              >
                <img className="" src={logo} alt="Ije" />
              </Link>
            </div>
            <div className=" 100:hidden phone:flex"></div>
          </div>
        </div>
        <div className=" 100:w-[85%] pt-[180px] md:w-[480px] gap-[64px] flex-col flex justify-start items-start ">
          <div className="flex-col flex gap-[12px] items-center justify-center w-full">
            <h4 className=" text-center text-blue-600 text-base font-semibold  leading-normal">Create Password</h4>
            <h2 className=" text-center text-neutral-700 md:text-3xl 100:text-[28px] 100:leading-8 font-semibold  md:leading-10">Set up your new password.</h2>
          </div>
          <div className="flex-col w-full flex gap-[32px] justify-start items-start ">
            <form className="flex-col w-full flex gap-[24px] justify-start items-start ">
              <TextInput
                label={"   Create Password"}
                type="password"
                value={formik.values.createPassword}
                onChange={formik.handleChange("createPassword")}
                placeholder="   Create Password"
                error={formik.touched.createPassword && formik.errors.createPassword}
              />

              <div className=" flex flex-col pl-[10px] gap-[10px] justify-start w-full items-start">
                <div className="flex   gap-[10px] justify-start w-fit items-center">
                  <img src={validFormValues.uppercase ? ticked : unticked} alt="" />
                  <h4 className={`text-[10px] ${validFormValues.uppercase ? "text-[#0C4CFF]" : " text-[#808990]"} tracking-[0.9%] leading-[17px] font-normal `}>One capital letter</h4>
                </div>
                <div className="flex   gap-[10px] justify-start w-fit items-center">
                  <img src={validFormValues.lowercase ? ticked : unticked} alt="" />
                  <h4 className={`text-[10px] ${validFormValues.lowercase ? "text-[#0C4CFF]" : " text-[#808990]"} tracking-[0.9%] leading-[17px] font-normal `}>One small letter</h4>
                </div>
                <div className="flex   gap-[10px] justify-start w-fit items-center">
                  <img src={validFormValues.length ? ticked : unticked} alt="" />
                  <h4 className={`text-[10px] ${validFormValues.length ? "text-[#0C4CFF]" : " text-[#808990]"} tracking-[0.9%] leading-[17px] font-normal `}>At least 8 characters long</h4>
                </div>{" "}
                <div className="flex   gap-[10px] justify-start w-fit items-center">
                  <img src={validFormValues.numbers ? ticked : unticked} alt="" />
                  <h4 className={`text-[10px] ${validFormValues.numbers ? "text-[#0C4CFF]" : " text-[#808990]"} tracking-[0.9%] leading-[17px] font-normal `}>At least 1 digit</h4>
                </div>{" "}
                <div className="flex   gap-[10px] justify-start w-fit items-center">
                  <img src={validFormValues.specialChar ? ticked : unticked} alt="" />
                  <h4 className={`text-[10px] ${validFormValues.specialChar ? "text-[#0C4CFF]" : " text-[#808990]"} tracking-[0.9%] leading-[17px] font-normal `}>At least 1 symbol (@, &, $...)</h4>
                </div>
              </div>

              <TextInput
                label={" Confirm password"}
                type="password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange("confirmPassword")}
                placeholder=" Confirm password"
                error={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />
            </form>
            {/* <Link className="w-full" to=""> */}
            <div onClick={formik.handleSubmit} className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-7 py-4 w-full hover:cursor-pointer">
              {isLoading ? "Loading..." : "Submit"}
            </div>
            {/* </Link> */}
          </div>
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setModal(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Set Successfully</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">Your password has been resetted correctly</p>
                    </div>
                  </div>
                  <Link className=" w-full" to="/auth/login">
                    <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">Back to Login</div>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default CreatePassword;
