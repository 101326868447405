import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "./SideBar";
import emptyState from "../Images/emptyState.svg";
import add from "../Images/add.svg";
// import campaign from "../Images/Maincampaign.png";
import WalletRequest from "../../utils/services/walletRequests";
import cancel from "../Images/Cancel.svg";
import helpIcon from "../Images/helpIcon.svg";
import edit from "../Images/edit.svg";
import al from "../Images/alert-circle.svg";
import copy from "../Images/copy.svg";
import CampaignRequest from "../../utils/services/campaignRequests";
import { toast } from "react-toastify";
import { CurrencyFormatter, formatDate } from "../../utils/helpers/helper";
import left from "../Images/arrow-left-01.svg";
import right from "../Images/arrow-right-01.svg";
import { useAppSelector } from "../../utils/redux/store";

function CampaignUser() {
  const [closeCampaign, setCloseCampaign] = useState(false);
  const [isData, setData] = useState({});
  const [isContributors, setContributors] = useState([]);
  // const progressBar = "0%";
  const [isLoading, setLoading] = useState(false);
  const [walletDetails, setWalletDetails] = useState(null);

  const getCampaigns = async () => {
    await CampaignRequest.getCampaigns().then((res) => {
      if (res.data.result.length !== 0) {
        setData(res.data.result);
      } else {
        setData(null);
      }
    });
  };
  const getWalletDetials = async () => {
    try {
      const res = await WalletRequest.getWalletDetails();

      setWalletDetails(res.data.result.userWalletDetails); // Set wallet details in state
    } catch (error) {
      console.error("Error fetching wallet details:", error);
    }
  };
  const getCampaignsContributors = async () => {
    await CampaignRequest.getCampaignContributors().then((res) => {
      setContributors(res.data.result);
    });
  };
  useEffect(() => {
    getCampaignsContributors();
    getCampaigns();
    getWalletDetials();
  }, []);

  const copyLink = () => {
    const shareUrl = `${window.location.origin}/usercampaign/${isData.campaignUuid}/${isData?.campaignResponse?.fundingGoal}`;

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success("Copied", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const { firstName, email } = useAppSelector((state) => state.auth);

  const closeCampaignFunc = async () => {
    setLoading(true);
    await CampaignRequest.closeCampaignUser(email).then((res) => {
      setLoading(false);
      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setCloseCampaign(false);
        getCampaigns();
        getWalletDetials();
      } else {
        toast.error(res?.data?.message || "an error occures", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    });
  };
  const totalFundsRaised = walletDetails ? CurrencyFormatter(walletDetails.dollarAmount || 0, "$") : "Loading...";
  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [
    { src: isData?.campaignImage, alt: "Campaign Image" },
    { src: isData?.campaignVideo, alt: "Campaign Video" },
  ];
  const isVideo = (src) => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = src?.split(".").pop();
    return videoExtensions.includes(extension);
  };

  const handleNextSlide = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrevSlide = () => {
    setActiveSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleDotClick = (index) => {
    setActiveSlide(index);
  };
  return (
    <>
      <SideBar />
      <div className=" flex pb-[50px] 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className=" w-full 100:flex-col phone:flex-row 100:justify-start 100:gap-[24px] 100:items-stretch  phone:justify-between phone:items-center  inline-flex">
            <h3 className="text-[#3a3c3f] text-2xl font-medium leading-[38px]">Campaigns</h3>
            <div className=" 100:w-full phone:w-fit 100:flex-col phone:flex-row  flex gap-2.5 100:items-end justify-end md:items-center">
              {isData === "" || isData === null ? (
                <Link className=" 100:w-full phone:w-fit" to="/user/campaign-form">
                  <div className="px-4 py-2.5 h-10 bg-[#0c4cff] rounded-lg shadow 100:w-full phone:w-fit  border border-[#0c4cff] justify-center items-center gap-2 flex text-white text-sm font-medium  leading-tight">
                    <img alt="Add" src={add} />
                    Create Campaign
                  </div>
                </Link>
              ) : (
                <div className="px-4 py-2.5 h-10 bg-[#b3dfff] 100:w-full phone:w-fit cursor-not-allowed rounded-lg shadow border border-[#b3dfff] justify-center items-center gap-2 flex text-white text-sm font-medium  leading-tight">
                  <img alt="Add" src={add} />
                  Create Campaign
                </div>
              )}
              <button onClick={() => setCloseCampaign(true)} className="leading-[20px] bg-white rounded-lg shadow border border-[#aab0b6]  text-[#4a4f54] text-sm font-medium flex justify-center  h-10 px-4 py-2.5 100:w-full phone:w-fit ">
                Close Campaign
              </button>
            </div>
          </div>
          {isData === "" || isData === null ? (
            <>
              <div className="h-fit py-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
                <img className=" 100:w-[100px] md:w-[150px]" src={emptyState} alt="" />
                <h4 className="text-center text-[#aab0b6] text-xs font-normal leading-normal">Nothing to show here</h4>
              </div>
            </>
          ) : (
            <>
              <div className=" w-full ">
                <div className=" bg-white w-full h-fit gap-[24px] flex flex-col items-start justify-start px-[24px] pt-[24px] pb-[48px] rounded-[12px]  ">
                  <div
                    className=" w-full h-[349px] pt-1.5 flex flex-col justify-between rounded-[8px] bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${slides[activeSlide].src})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    {" "}
                    {/* Help message */}
                    {isData?.state !== "CLOSED" && (
                      <div className="pl-1.5 whitespace-pre-wrap mt-4 ml-4 w-fit pr-2 py-0.5 bg-blue-50 rounded-2xl flex items-center gap-1">
                        <img src={helpIcon} alt="Donate" />
                        <h5 className="text-center text-blue-600 text-xs font-medium">Help {firstName} achieve their dreams</h5>
                      </div>
                    )}
                    {isVideo(slides[activeSlide].src) && (
                      <video className="h-full w-full rounded-[8px] object-cover" controls>
                        <source src={slides[activeSlide].src} type={`video/${slides[activeSlide].src.split(".").pop()}`} />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {/* Navigation buttons */}
                    <div className=" w-full h-full   flex justify-between items-center">
                      <button onClick={handlePrevSlide} className=" transform -translate-y-1/2">
                        <div className="w-12 h-12 justify-center flex items-center bg-gray-400 rounded-3xl">
                          <img src={left} alt="Arrow" />
                        </div>
                      </button>
                      <button onClick={handleNextSlide} className=" transform -translate-y-1/2">
                        <div className="w-12 h-12 justify-center flex items-center bg-gray-400 rounded-3xl">
                          <img src={right} alt="Arrow" />
                        </div>
                      </button>
                    </div>
                    {/* closed  */}
                    {isData?.state === "CLOSED" && (
                      <div className="w-full h-full  px-6 py-4 bg-white/70 rounded-lg rounded-br-lg  flex  items-center">
                        <h3 className="w-full text-[#985108] text-2xl font-bold text-center justify-center justify-items-center ">Campaign Closed</h3>
                      </div>
                    )}
                    {/* Campaign Title */}
                    {isData?.state !== "CLOSED" && (
                      <div className="w-full h-fit px-6 py-4 mt-[27vh] bg-white/10 rounded-bl-lg rounded-br-lg backdrop-blur-xl flex items-start justify-end">
                        <h3 className="w-full  text-white text-xl font-bold">{isData?.campaignTitle}</h3>
                      </div>
                    )}
                  </div>

                  <div className=" w-full h-fit justify-between items-start flex mt-10">
                    <div className="w-[80%] text-pretty text-[#6c757d] text-sm text-[14px] font-normal break-words">{isData?.description}</div>
                    <div>
                      {isData?.state !== "CLOSED" && (
                        <Link onClick={() => window.scrollTo(0, 0)} to="/user/campaign-form">
                          <div className="h-[22px] pl-1.5 pr-2 py-0.5 bg-[#e8f7ff] text-center text-[#0c4aff] text-xs font-medium  leading-[18px] rounded-2xl justify-center items-center gap-1 inline-flex">
                            <img src={edit} alt="" />
                            <div className="">Edit</div>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                  {/* progressBar */}
                  <div className=" w-full flex-grow flex justify-start flex-col items-start gap-[10px]">
                    <div className=" h-[8px] items-start flex justify-start bg-[#E8F7FF] rounded-[8px] w-full">
                      <div style={{ width: `${isData?.progress || 0}%` }} className={`h-full  ${isData?.state === "CLOSED" ? "bg-[#E29400]" : "bg-[#0C4CFF] "} rounded-[8px] `}></div>
                    </div>
                    <div className=" w-full flex justify-between items-center">
                      <h4 className="text-neutral-600 text-xs font-semibold">
                        {" "}
                        {/* {CurrencyFormatter(
                          isContributors?.reduce((acc, item) => acc + item?.amount, 0),
                          "$"
                        )}{" "} */}
                        {totalFundsRaised} of {CurrencyFormatter(parseFloat(isData?.fundingGoal || 0), "$")}
                      </h4>
                      <span className=" text-[#4A4F54] font-medium leading-[24px] text-[12px] ">{isData?.progress || 0}%</span>
                    </div>{" "}
                  </div>
                  <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                    <h3 className=" text-[#808990] text-xs font-medium ">List of Contributors</h3>

                    {Array.isArray(isContributors) &&
                      isContributors?.map((item) => (
                        <div className="  flex-col justify-start items-start gap-2 flex">
                          <h5 className="text-[#6c757d] text-[13px] font-medium  leading-normal">
                            {item?.contributor !== "null" ? item?.contributor : "Anonymous"} - <span className="text-[#4a4f54]">{CurrencyFormatter(item?.amount, "$")}</span> - {formatDate(new Date(item?.date))}
                          </h5>
                        </div>
                      ))}
                  </div>

                  {isData?.status !== "CLOSED" && (
                    <button className="flex-col justify-start items-start gap-2.5 inline-flex" onClick={copyLink}>
                      <h3 className=" text-[#808990] text-xs font-medium ">Copy Link</h3>
                      <div className=" justify-start items-center gap-2 flex">
                        <img alt="email" src={copy} />
                      </div>
                    </button>
                  )}

                  {isData?.status !== "CLOSED" && (
                    <section className=" justify-center flex items-center w-full  ">
                      <Link onClick={() => window.scrollTo(0, 0)} className=" phone:w-fit 100:w-full" to={`/payment/donate/${isData?.id}/${isData?.fundingGoal}`}>
                        <div className=" border-teal-600 bg-teal-600 border-solid border-[1px] rounded-[8px] 100:text-sm  md:text-lg text-white text-sm font-medium leading-tight flex justify-center  h-[40px] items-center 100:w-full phone:w-[305px]">
                          Donate now
                        </div>
                      </Link>
                    </section>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {closeCampaign && (
        <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
          <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
            <div onClick={() => setCloseCampaign(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
              <img className=" " src={cancel} alt="Cancel" />
            </div>
          </section>
          <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
            <div className=" 100:w-full iphone:w-96 h-fit p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
              <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                <div className="w-full flex-col justify-start items-center gap-5 flex">
                  <div className="w-12 h-12   justify-center flex  items-center  bg-[#fffbc5] rounded-[28px] border-8 border-[#ffffea]">
                    <img className=" " src={al} alt="Alert" />
                  </div>
                  <div className="w-full flex-col justify-start items-center gap-2 flex">
                    <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Confirmation</h3>
                    <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">Are you sure you want to close this campaign?</p>
                  </div>
                </div>
                <div className=" w-full flex justify-start items-center flex-col gap-2.5">
                  <button onClick={closeCampaignFunc} className="leading-[20px]  bg-[#b7192c] rounded-lg shadow border border-[#b7192c] text-base text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">
                    {isLoading ? "Loading..." : "Proceed"}
                  </button>
                  <button onClick={() => setCloseCampaign(false)} className="leading-[20px]   rounded-lg shadow border border-[#AAB0B6] text-[#4a4f54] text-base flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default CampaignUser;
