function HowIjeWorks() {
  const features = [
    {
      id: 1,
      icon: (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.5 22.16H6.59087C5.04549 22.16 3.81631 21.4081 2.71266 20.3566C0.453365 18.2042 4.1628 16.484 5.57757 15.6416C7.67837 14.3907 10.1368 13.9319 12.5 14.2652C13.3575 14.3862 14.1926 14.6114 15 14.9409"
            stroke="#0C4AFF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M16.5 6.66003C16.5 9.14532 14.4853 11.16 12 11.16C9.51472 11.16 7.5 9.14532 7.5 6.66003C7.5 4.17475 9.51472 2.16003 12 2.16003C14.4853 2.16003 16.5 4.17475 16.5 6.66003Z" stroke="#0C4AFF" stroke-width="2" />
          <path d="M18.5 22.16L18.5 15.16M15 18.66H22" stroke="#0C4AFF" stroke-width="2" stroke-linecap="round" />
        </svg>
      ),
      title: "Sign Up",
      description: "Start your journey today. Sign up, submit all documents required and get approved.",
    },
    {
      id: 2,
      icon: (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 18.16C14 18.16 15 18.16 16 20.16C16 20.16 19.1765 15.16 22 14.16" stroke="#0C4AFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M13 22.16H6.59087C5.04549 22.16 3.81631 21.4081 2.71266 20.3566C0.453365 18.2042 4.1628 16.484 5.57757 15.6416C8.75591 13.7491 12.7529 13.6697 16 15.4033"
            stroke="#0C4AFF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M16.5 6.66003C16.5 9.14532 14.4853 11.16 12 11.16C9.51472 11.16 7.5 9.14532 7.5 6.66003C7.5 4.17475 9.51472 2.16003 12 2.16003C14.4853 2.16003 16.5 4.17475 16.5 6.66003Z" stroke="#0C4AFF" stroke-width="2" />
        </svg>
      ),
      title: "Create Campaign",
      description: "Once approved, you can create a campaign to raise funds from family and friends, with the option to keep it private or public.",
    },
    {
      id: 3,
      icon: (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0502 3.16003C7.19782 3.16697 5.1805 3.26155 3.89128 4.55077C2.5 5.94205 2.5 8.18129 2.5 12.6598C2.5 17.1382 2.5 19.3775 3.89128 20.7688C5.28257 22.16 7.5218 22.16 12.0003 22.16C16.4787 22.16 18.718 22.16 20.1093 20.7688C21.3985 19.4795 21.4931 17.4622 21.5 13.6098"
            stroke="#0C4AFF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.5556 13.16C10.8322 4.02638 17.3023 1.43557 22.4805 2.32442C22.6896 5.35139 21.2085 6.48485 18.3879 7.00828C18.9326 7.57739 19.895 8.29358 19.7912 9.18882C19.7173 9.82589 19.2846 10.1385 18.4194 10.7636C16.5231 12.1336 14.3264 12.9976 11.5556 13.16Z"
            stroke="#0C4AFF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M9.5 17.16C11.5 11.66 13.4604 9.7964 15.5 8.16003" stroke="#0C4AFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      title: "Access Loan",
      description: "If your campaign doesn't cover 100% of the funds, Ije will provide the rest as a loan with flexible repayment terms of up to 5 years.",
    },
    {
      id: 4,
      icon: (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.5 4.66003C6.00442 5.86107 3.5 9.10179 3.5 12.9111C3.5 14.1179 3.75134 15.2676 4.20591 16.3135M15.5 4.66003C18.9956 5.86107 21.5 9.10179 21.5 12.9111C21.5 13.9337 21.3195 14.9153 20.9879 15.8274M17 20.4896C15.6762 21.234 14.1393 21.66 12.5 21.66C10.8607 21.66 9.32378 21.234 8 20.4896"
            stroke="#0C4AFF"
            stroke-width="2"
          />
          <path d="M15.5 5.16003C15.5 6.81689 14.1569 8.16003 12.5 8.16003C10.8431 8.16003 9.5 6.81689 9.5 5.16003C9.5 3.50318 10.8431 2.16003 12.5 2.16003C14.1569 2.16003 15.5 3.50318 15.5 5.16003Z" stroke="#0C4AFF" stroke-width="2" />
          <circle cx="5.5" cy="19.16" r="3" stroke="#0C4AFF" stroke-width="2" />
          <circle cx="19.5" cy="19.16" r="3" stroke="#0C4AFF" stroke-width="2" />
        </svg>
      ),
      title: "Tuition and Living Expenses Remitted",
      description: "Ije pays 100% of the tuition directly to your school. After a successful visa, you'll receive 30% upfront, with the rest sent once you've relocated and settled.",
    },
  ];

  return (
    <>
      <div className=" w-full py-[96px] 100:px-[24px] md:px-[64px]  bg-[#EFFEFA] flex-col flex justify-center items-center">
        <div className="gap-[64px] 100:w-[85%] xl:w-[1280px] flex flex-col justify-start">
          <div className=" 100:w-full lg:w-[654px] gap-[20px] text-left flex flex-col items-start justify-start">
            <section className=" gap-[12px] text-left w-full flex flex-col ">
              <h5 className=" text-blue-700 text-base font-medium  leading-tight">How Ije Works</h5>

              <h2 className=" font-semibold tracking-[-2px] text-[#3A3C3F] text-[38px] leading-[42px] ">
                {" "}
                Getting Started with <span className="  text-[#0C4CFF]"> Ije</span> is Easy!
              </h2>
            </section>
            <p className="text-[20px]  leading-[30px] text-[#6C757D] w-full font-normal">Got a postgraduate offer in the UK, US, Canada, or Europe? Ije can help you raise 100% of your tuition and living expenses. </p>
          </div>
          <div className=" w-full  100:flex-col lg:flex-row gap-[32px] text-center flex  items-start justify-start">
            {features.map((item, id) => (
              <div key={id} className=" w-full flex flex-col justify-start items-start gap-[20px]">
                <div className="w-12 h-12  bg-[#E8F7FF] flex justify-center items-center rounded-3xl">{item.icon}</div>
                <div className=" flex flex-col justify-start items-start text-left gap-[8px]">
                  <h4 className="  text-neutral-700 text-[20px] font-medium  leading-[30px]">{item.title}</h4>
                  <p className="text-gray-500 text-base font-normal text-justify  leading-normal">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default HowIjeWorks;
