import Header from "../LandingPage/Header";
import user from "../Images/user.svg";
import users from "../Images/users.svg";
import donate from "../Images/donate.svg";
import secure from "../Images/Secure.svg";
import triangle from "../Images/triangle.svg";
import campaign from "../Images/Maincampaign.png";
import ContactUs from "../LandingPage/ContactUs";
import Footer from "../LandingPage/Footer";
import { Link, useParams } from "react-router-dom";
import MiscRequest from "../../utils/services/miscRequests";
import { useEffect, useState } from "react";
import { CurrencyFormatter } from "../../utils/helpers/helper";
import { toast } from "react-toastify";
import left from "../Images/arrow-left-01.svg";
import right from "../Images/arrow-right-01.svg";

function IndividualCampaign() {
  const { id } = useParams();

  const [isData, setData] = useState({});

  const getCampaignDetails = async () => {
    await MiscRequest.getCampaigns(id).then((res) => {
      console.log("campginh details", res.data);
      setData(res.data.result)
    });
  };

  useEffect(() => {
    getCampaignDetails();
  }, []);

  const copyLink = () => {
    const shareUrl = `${window.location.origin}/usercampaign/${isData.campaignResponse?.campaignUuid}/${isData?.campaignResponse?.fundingGoal}`;

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success("Copied", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [
    { src: isData?.campaignResponse?.campaignImage, alt: "Campaign Image" },
    { src: isData?.campaignResponse?.campaignVideo, alt: "Campaign Video" },
  ];

  const isVideo = (src) => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = src?.split(".").pop();
    return videoExtensions.includes(extension);
  };
  const handleNextSlide = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrevSlide = () => {
    setActiveSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleDotClick = (index) => {
    setActiveSlide(index);
  };
  return (
    <>
      <div className=" bg-white w-full h-fit">
        <Header />
        <section className="w-full pt-[220px] py-[96px] h-fit flex justify-center items-center ">
          <div className=" 100:w-[95%] gap-[64px] h-fit flex justify-start   px-[10px] items-start 100:flex-col xl:flex-row xl:w-[1200px] ">
            <div className=" 100:w-full xl:w-[836px]  flex justify-center items-center flex-col gap-[24px]">
              <h1 className=" w-full flex justify-start items-start font-bold leading-[33px] text-[#4A4F54] text-[32px] capitalize">
                Help {isData?.campaignResponse?.campaignTitle}
              </h1>
              <div className="relative w-full">
                {/* <img
                  className="h-[349px] rounded-[8px] bg-contain object-cover  w-full"
                  src={slides[activeSlide].src}
                  alt={slides[activeSlide].alt}
                /> */}

                {isVideo(slides[activeSlide].src) ? (
                  <video
                    className="h-[349px] rounded-[8px] bg-contain object-cover w-full"
                    controls
                  >
                    <source
                      src={slides[activeSlide].src}
                      type={`video/${slides[activeSlide].src.split(".").pop()}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    className="h-[349px] rounded-[8px] bg-contain object-cover w-full"
                    src={slides[activeSlide].src}
                    alt={slides[activeSlide].alt}
                  />
                )}

                {/* Navigation buttons */}
                <button
                  onClick={handlePrevSlide}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2"
                >
                  <div className="w-12 h-12 justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                    <img className=" " src={left} alt="Arrow" />
                  </div>
                </button>
                <button
                  onClick={handleNextSlide}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 "
                >
                  <div className="w-12 h-12 justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                    <img className=" " src={right} alt="Arrow" />
                  </div>
                </button>
              </div>

              {/* Indicators */}
              <div className="w-10 h-3.5 justify-start items-center gap-2.5 inline-flex mt-4">
                {slides.map((_, index) => (
                  <div
                    key={index}
                    onClick={() => handleDotClick(index)}
                    className={`w-3.5 h-3.5 rounded-full ${
                      index === activeSlide ? "bg-blue-600" : "bg-[#E8F7FF]"
                    } cursor-pointer`}
                  />
                ))}
              </div>
              <div className="w-full 100:flex-col phone:flex-row 100:items-start  100:gap-[12px]  flex justify-between phone:items-center  ">
                <section className="px-[8px] py-[6px] justify-start items-center flex gap-[4px] h-fit w-fit rounded-full bg-[#F5F6F6] ">
                  <div className=" p-[8px] rounded-full bg-white">
                    <img src={user} alt="" />
                  </div>
                  <p className=" font-medium leading-[18px] text-[#4A4F54] text-[12px]">
                    {isData?.campaignResponse?.creatorName} is organizing this
                    campaign
                  </p>
                </section>
                <section className="w-fit h-7 px-2 py-1.5 bg-[#E8F7FF] rounded-2xl justify-center items-center gap-1 inline-flex">
                  <img src={secure} alt="" />

                  <p className="text-center text-blue-600 text-xs font-medium leading-none">
                    Donation Secured
                  </p>
                </section>
              </div>
              {/* Border */}
              <div className=" w-full h-[1px] bg-[#E5E7E8]"></div>
              {/* Paragraph */}
              <div className="w-full h-fit ">
                <p className=" w-full text-pretty text-[#6c757d] text-sm text-[14px] font-normal break-words">
                  {isData?.campaignResponse?.description}{" "}
                </p>
              </div>
              {/* Border */}
              <div className=" w-full h-[1px] bg-[#E5E7E8]"></div>
              <section className="  flex justify-start items-start gap-[12px] w-full  ">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className=" w-full"
                  to={`/payment/donate/${isData?.campaignResponse?.id}/${isData?.campaignResponse?.fundingGoal}`}
                >
                  <div className="leading-[20px] bg-neutral-100 rounded-lg shadow border border-gray-600 text-[14px]  text-neutral-600  flex justify-center font-medium h-[40px] px-[10px] py-[8px] item-center w-full ">
                    Donate
                  </div>
                </Link>
                <button onClick={copyLink} className=" w-full" to="">
                  <div className="leading-[20px]  bg-neutral-100 rounded-lg shadow border border-gray-600 text-[14px]  text-neutral-600  flex justify-center font-medium h-[40px] px-[10px] py-[8px] item-center w-full">
                    Share
                  </div>
                </button>
              </section>
              {/* Border */}
              <div className=" w-full h-[1px] bg-[#E5E7E8]"></div>
              <div className="w-full h-16 rounded-full flex-col justify-start items-start gap-[4px] inline-flex">
                <h4 className="text-center text-neutral-600 text-sm font-semibold leading-none">
                  Organizer
                </h4>
                <section className=" py-[6px] justify-start items-center flex gap-[12px] h-fit w-fit   ">
                  <div className=" p-[8px] rounded-full bg-[#E8F7FF]">
                    <img src={user} alt="" />
                  </div>
                  <div className="w-20  flex-col justify-start items-start gap-1 inline-flex">
                    <h5 className="text-center text-neutral-600 text-xs font-medium ">
                      {isData?.campaignResponse?.creatorName}
                    </h5>
                    {/* <p className=" text-gray-500 text-xs font-normal ">
                      Lagos Nigeria
                    </p> */}
                  </div>
                </section>
              </div>
            </div>
            {/* side box */}
            <div className="xl:w-[334px] 100:w-full  h-fit p-4 bg-white rounded-lg shadow flex-col justify-start items-start gap-[16px] flex">
              <h4 className="text-neutral-600 text-[16px] leading-[24px] font-medium">
                {" "}
                {CurrencyFormatter(
                  isData?.campaignResponse?.amountContributed || 0,
                  "$"
                )}{" "}
                of{" "}
                {CurrencyFormatter(
                  isData?.campaignResponse?.fundingGoal || 0,
                  "$"
                )}{" "}
                Raised
              </h4>
              {/* progressBar */}
              <div className=" w-full flex-grow flex justify-start flex-col items-start gap-[10px]">
                <div className=" h-[8px] items-start flex justify-start bg-[#E8F7FF] rounded-[8px] w-full">
                  <div
                    className={`h-full  bg-[#0C4CFF] rounded-[8px] `}
                    style={{
                      width: `${isData?.campaignResponse?.progress || 0}%`,
                    }}
                  />
                </div>
                <div className=" w-full flex justify-end items-end">
                  <span className=" text-neutral-600 text-xs font-medium leading-tight">
                    {isData?.campaignResponse?.progress || 0}% funded
                  </span>
                </div>{" "}
              </div>
              <section className=" flex-col flex justify-start items-start gap-[12px] w-full  ">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className=" w-full"
                  to={`/payment/donate/${isData?.campaignResponse?.id}/${isData?.campaignResponse?.fundingGoal}`}
                >
                  <div className="leading-[20px] border-[#079A91] border-solid border-[1px] rounded-[8px] text-[14px] bg-[#079A91]  text-[#fff] flex justify-center font-medium h-[40px] px-[10px] py-[8px] item-center w-full ">
                    Donate
                  </div>
                </Link>
                <button onClick={copyLink} className=" w-full" to="">
                  <div className="leading-[20px] border-[#079A91] border-solid border-[1px] rounded-[8px] text-[14px] bg-[#CAFDF3]  text-[#4A4F54] flex justify-center font-medium h-[40px] px-[10px] py-[8px] item-center w-full ">
                    Share
                  </div>
                </button>
              </section>
              <section className=" py-[6px] justify-start items-center flex gap-[4px] h-fit w-full rounded-full  ">
                <div className=" p-[8px] rounded-full bg-white">
                  <img src={users} alt="" />
                </div>
                <p className=" font-medium leading-[18px] text-[#4A4F54] text-[12px]">
                  {isData?.walletTransactionsResponses?.length || 0} People just
                  donated
                </p>
              </section>

              {/* Border */}
              <div className=" w-full h-[1px] bg-[#E5E7E8]"></div>
              <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                {isData?.walletTransactionsResponses?.map((item) => (
                  <section className=" py-[6px] justify-start items-center flex gap-[12px] h-fit w-fit   ">
                    <div className=" p-[8px] rounded-full bg-[#F5F6F6]">
                      <img src={donate} alt="" />
                    </div>
                    <div className="w-20 flex-col justify-start items-start gap-1 inline-flex">
                      <h5 className=" text-neutral-600 text-[10px] font-medium ">
                        Anonymous
                      </h5>
                      <p className=" text-gray-500 whitespace-nowrap text-[10px] font-normal ">
                        <span className="text-neutral-600 font-medium ">
                          {CurrencyFormatter(parseFloat(item.amount), "$")}
                        </span>{" "}
                        donated
                      </p>
                    </div>
                  </section>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      <ContactUs />
      <Footer />
    </>
  );
}

export default IndividualCampaign;
